<template>
  <div class="container">
    <div class="text-box" v-if="noWX">
      <div class="icon-box" :style="{marginTop:`${height}px`}">
        <div class="icon">
          <van-icon name="arrow-left" color="#fff" size="28" v-show="noWX" @click="back()"/>
        </div>
      </div>
    </div>
    <van-swipe class="my-swipe" :autoplay="6000" :duration="800" indicator-color="white" :show-indicators="false">
      <van-swipe-item><img src="@/assets/images/lb-1.jpg" /></van-swipe-item>
      <van-swipe-item><img src="@/assets/images/lb-3.jpg" /></van-swipe-item>
      <van-swipe-item><img src="@/assets/images/lb-4.jpg" /></van-swipe-item>
      <van-swipe-item><img src="@/assets/images/lb-2.jpg" /></van-swipe-item>
    </van-swipe>
    <div class="msg-contain">
      <div class="form">
        <van-field
          v-model="cityName"
          readonly
          required
          placeholder="请选择城市"
          right-icon="arrow-down"
          @click="showPicker = true"
          class="scene"
          @blur="requrieChange"
        />
        <van-field
          style="margin-top:12px"
          v-model="mobile"
          type="tel" 
          maxlength="11"
          required
          placeholder="请输入手机号"
          class="scene"
          @blur="requrieChange"
        />
        <van-field
          style="margin-top:12px"
          v-model="nickName"
          maxlength="20"
          placeholder="请输入您的称呼"
        />
        <van-field
          style="margin-top:12px"
          v-model="passengerRemark"
          :rows="4"
          type="textarea"
          placeholder="可备注乘车人数、偏好路线、用车数量、车辆装饰、代买物品等定制需求"
          :maxlength="200"
        />
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
            :visible-item-count="3"
            :columns="columns"
            :columns-field-names="customFieldName"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>
      <div class="tips">详情可咨询礼帽客服：<span class="tel" @click="callPhoneNum">400-009-0909</span></div>
      <div class="btn-box">
        <button @click="submit" :disabled="btnDisabled" class="btn">确认提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getEnv,initIosAndAndroidEnv,getIosAndAndroidData,initAlipayEnv} from "../../../utils/index"
import { getCity, getinviteData } from "@/api/app";
export default {
  data() {
    return {
      columns:[],
      customFieldName: {
        text:'name',
        value:'code'
      },
      isSet: false,
      height:'30',
      noWX: true,
      loading:true,
      showPicker: false,
      btnDisabled: true,
      cityName: '杭州', 
      cityCode: '330100', 
      mobile: '',  
      nickName: '',  
      passengerRemark: ''
    };
  },
  computed:{
      
  },
  mounted(){
    this.getData()
    this.init()
    setTimeout(() => {
      this.loading = false
    }, 300);
  },
  methods: {
      // 返回
      back() {
        let ENV = getEnv();
        if (ENV === "ios" || ENV === "andriod") {
            initIosAndAndroidEnv(ENV, "Other/closeNav", "back-20220914");
        }
        console.log("只有一个页面")
      },
      callPhoneNum(){
        let ENV = getEnv();
        if(ENV === "ios") {
           initIosAndAndroidEnv(ENV, "Other/makeCall", "call-20230309", {
              "phoneNumber": "400-009-0909"
            });
        }else{
          window.location.href = 'tel://400-009-0909'
        }
      },
      onConfirm(value){
        this.showPicker = false;
        this.cityName = value.name;
        this.cityCode = value.code
      },
      init(){
        let ENV = getEnv();
        if (ENV === "ios" || ENV === "andriod") {
            this.noWX = true
            initIosAndAndroidEnv(ENV, "navigation/visible", "family-20230309", {
              show: false,
            });
            // 状态栏颜色深浅
            initIosAndAndroidEnv(ENV, "statusBar/textColor", "family-20230309", {
              dark: false, //false 浅色
            });
            //获取状态栏高度
            initIosAndAndroidEnv(ENV, "Other/getStatusbarHeight", "height-20230309");
            getIosAndAndroidData(ENV, [
              {
                id: "height-20230309",
                callback: this.heightData,
              },
            ]);
        }  else if(ENV === 'alipay'){
          this.noWX = false
          initAlipayEnv()
        } else {
          this.noWX = false
        }
    },
    requrieChange(val) {
      if(this.cityName && this.mobile) {
        this.btnDisabled = false
      }else{
        this.btnDisabled = true
      }
    },
    heightData(res){
      this.height = Number(res.height);
    },
    getData(){
      getCity().then(res=>{
        if(res.code==1000){
            this.columns = res.data
        } else {
            Toast(res.message)
        }
      })
    },
    submit(){
      let pattern = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
      if(!this.cityCode){
        Toast('请选择城市')
        return
      }
      if(!this.mobile){
        Toast('请输入手机号')
        return
      }

      let data = {
          type: 30, //学生接送10 康养就医20
          scene: 31,
          cityCode:this.cityCode,
          mobile:this.mobile,
          nickName:this.nickName.replace(pattern, ''),
          passengerRemark: this.passengerRemark.replace(pattern, ''),
      }
      getinviteData(data).then(res=>{
          if(res.code==1000){
              Toast('提交成功，我们将尽快与您联系！')
          } else {
              Toast(res.message)
          }
          console.log("res",res)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  overflow: scroll;
  .msg-contain{
    padding: 26px 16px 16px;
    box-sizing: border-box;
    background: url('../../../assets/images/msg-bg.png');
    background-size: 100% 100%;
  }
  .tips{
    margin-top: 16px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255,255,255,0.5);
    line-height: 20px;
    .tel {
      color: #FFEC00;
    }
  }
  .scene {
    &::before {
      content: "*";
      font-size: 16px;
      color: #FFEC00;
      padding-right: 4px;
      margin-top: 3px;
      height: 10px;
    }
  }
  .grad {
    margin-top: 12px;
    display: flex;
    justify-content: space-around;
    .selected {
      background: #fffcdc;
      border: #ffec00 2px solid;
    }
    .unselect {
      border: #ebebeb 1px solid;
    }
    .item {
      box-sizing: border-box;
      width: 108px;
      height: 58px;
      border-radius: 8px;
      color: #666;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: #ebebeb 1px solid;
      .flex-a {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      img {
        // display: block;
        width: 18px;
        height: 18px;
      }
    }
  }
  .btn-box {
    margin-top: 20px;
    .btn {
      height: 56px;
      width: 100%;
      background: #EBEBEB;
      border: none;
      border-radius: 8px;
      color: #333;
      font-size: 16px;
      line-height: 18px;
      &:disabled {
        background: rgba(235,235,235,0.3);
        color: #999999;
      }
    }
  }
  .text-box {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      color: #fff;
      .icon-box {
        .icon {
          z-index: 10000;
          position: fixed;
          text-align: left;
          padding: 20px 0 15px 15px;
        }
        .text{
          width: 100%;
          font-size: 20px;
          color: #000;
          position: fixed;
          text-align: center;
          padding: 20px 0 15px 0;
        }
      }
  }
}
</style>
<style lang="scss">
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 0;
  line-height: 0;
  text-align: center;
  background-color: #39a9ed;
  img {
    width: 100%;
  }
}
.van-cell {
  padding: 13px 16px;
  border-radius: 8px;
  border: none;
  background: #788290
}
.van-field__control {
  color: #fff;
  font-size: 15px;
  color: #FFFFFF;
  line-height: 21px;
}
.van-cell::after {
  border-bottom: none;
}
.van-picker__confirm {
  color: #333333;
  font-size: 15px;
}
.van-picker__cancel {
  font-size: 15px;
  color: #787a84;
}
.van-popup--bottom.van-popup--round {
  border-radius: 12px 12px 0 0;
}
.van-field__control::-webkit-input-placeholder {
  color: #A0A6B0;
}
</style>